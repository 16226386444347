@use "../node_modules/@limblecmms/lim-ui/style/lim-ui";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-animations";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-colors";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-modals";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-tooltips";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-list-items";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-pick-list";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-scrollbar";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-tables";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-mobile";
@use "../node_modules/@limblecmms/lim-ui/style/lim-ui-inputs";

/**
 * This is where we import all of the stylesheets we need from our `assets` folder.
 * Keep in mind that the order of imports does matter.
 *
 * Avoid adding style rules directly to this file. This file is just for
 * aggregating rules from other files.
 */

// doka is a third party service that we use for viewing images.
@import "./assets/js/doka.scss";
@import "./assets/styles/ag-grid.overrides.scss";
@import "./assets/styles/beamer.overrides.scss";
@import "./assets/styles/doka.overrides.scss";
@import "./assets/styles/materialdesignicons.css";
@import "./assets/styles/gridster.overrides.scss";
@import "./assets/styles/leaflet-geo-map.overrides.scss";
@import "./assets/styles/chart-js-legend.scss";
@import "./app/tasks-analytics/calendar/full-calendar.overrides.scss";
@import "./assets/styles/print-styles.scss";
@import "./assets/styles/primeng.overrides.scss";
@import "./assets/styles/nxt-components.overrides.scss";
@import "@angular/cdk/overlay-prebuilt.css";

//applying border box to all elements, as per https://css-tricks.com/box-sizing/#aa-good-better-and-probably-best-box-sizing-reset-methods
//styled all our elements using border-box while bootstrap was in the code base. Then needed to add this back after removing bootstrap
*,
*:before,
*:after {
   box-sizing: border-box;
}

// Bootstrap had this styling. We should probably remove it and use the *ngIf everywhere instead.
[hidden] {
   display: none !important;
}

//Browser override reset
body {
   @include lim-ui.fonts-body;
   margin: 0;
   background-color: var(--colors-page-background);
}

html,
body {
   // Get rid of iOS pull to refresh
   overflow: hidden;
   height: 100%;
}

p {
   margin-block: auto;
   margin-inline: auto;
}
