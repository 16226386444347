/**
 * PrimeNG overrides
 */

/**
 * allows fluid width
 */
input.p-inputtext-fluid {
   max-width: unset;
}
