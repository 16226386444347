/*
 * AG Grid Overrides
 */

// Overrides lim-ui-scrollbar and makes the scrollbar visible (when present).
.ag-body-horizontal-scroll-viewport {
   scrollbar-width: thin;
}

// Note: this may only affect the community version. Perhaps, with
// a paid license, this block wouldn't be necessary.
// Without the following style update, the scrollbars only show
// when the user is actively scrolling on Mac (maybe Windows too - don't know).
// For those with a mouse wheel, this isn't a huge issue for vertical
// scrolling. For horizontal scrolling, though - even with a
// mouse wheel, it's not good UX.
.ag-root-wrapper:hover {
   .ag-body-horizontal-scroll,
   .ag-body-vertical-scroll {
      &.ag-scrollbar-invisible {
         opacity: 1;
         visibility: visible;
      }
   }
}
