.fc .fc-daygrid-day-number,
.fc-col-header-cell-cushion {
   @include lim-ui.fonts-h3;
   color: var(--colors-header-text);
   text-decoration: none;
}

.fc-col-header-cell {
   background-color: var(--colors-level-two-background);
}

.fc-scrollgrid {
   width: 100%;
   max-height: 789;
   overflow: hidden;
}

.fc-daygrid-body {
   width: 100%;
   max-height: 756px;
   overflow: scroll;
}

.fc-daygrid-body::-webkit-scrollbar {
   width: 0 !important;
}

.fc-event-title-container {
   padding-left: lim-ui.$spacing-extra-small;
   & .fc-event-title {
      @include lim-ui.fonts-body;
      color: var(--colors-header-text);
   }
   & .fc-event-title.fc-sticky {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
   }
}

.fc-black-text {
   .fc-event-title {
      @include lim-ui.fonts-body;
      color: var(--colors-header-text);
   }
}

.fc-white-text {
   .fc-event-title {
      @include lim-ui.fonts-body;
      color: var(--colors-white-text);
   }
}

.fc-daygrid-event {
   white-space: normal !important;
   align-items: normal !important;
}
